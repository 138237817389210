import * as React from 'react';

import {
  Text,
  BlogImage,
  BlogInfographics,
  BlogLiterature,
} from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import infographic from '../../../images/educational-corner/blog2/infografika.png';
import linguisticPeriod from '../../../images/educational-corner/blog2/Jezicno-razdoblje-jezika-i-govora.png';
import preLinguisticPeriod from '../../../images/educational-corner/blog2/Predjezicno-razdoblje-jezika-i-govora.png';

const LanguageDevelopment = () => {
  return (
    <PageNew
      title="KOKOLINGO - Razvoj jezika i govora"
      description="Kokolingo pomaže u razvoju pravilnog jezika i govora. 
      Govor možemo shvatiti kao sredstvo koje omogućava prenošenje 
      jezičnih informacija."
      headline="Razvoj jezika i govora"
    >
      <Text as="p">
        Jezik i govor dva su povezana, ali ne i istoznačna pojma. Govor je
        osnovni način komunikacije među ljudima. Njime možemo izražavati svoje
        misli, osjećaje, želje i potrebe. Da bi se govor razvio, treba imati
        uredne intelektualne sposobnosti, uredan sluh i uredno razvijene govorne
        organe.
        <b> Jezik je bogat sustav dogovorenih simbola i pravila </b>
        koji se povezuju u veće cjeline, a kojima se određena skupina ljudi
        sporazumijeva.
        <b>
          {' '}
          Govor možemo shvatiti kao sredstvo koje omogućava prenošenje jezičnih
          informacija.{' '}
        </b>
        Bitan je faktor u razvoju jezika i govora okolina kojom je dijete
        okruženo. Ona mora biti poticajna za njegov jezično-govorni, ali i
        cjelokupni razvoj.
      </Text>
      <Text as="h2">Jezično-govorni razvoj</Text>
      <Text as="p">
        Jezično-govorni razvoj teče postupno, po određenim fazama.
        <b> Dijeli se na dva razdoblja, predjezično i jezično razdoblje. </b>U
        svakom od tih razdoblja dijete usvaja različite vještine, raste i
        razvija se.
      </Text>
      <Text as="h2">Predjezično razdoblje</Text>
      <Text as="p">
        Predjezično razdoblje traje od rođenja do prve godine djetetova života.
        <b>
          {' '}
          To je razdoblje u kojem dijete komunicira s okolinom, ali ne proizvodi
          jezične strukture kao što su riječi ili rečenice.{' '}
        </b>
        Prvi komunikacijski čin djeteta je plač. Njime će dijete u prvim
        tjednima života izražavati različite potrebe, npr. da ga treba presvući
        ili da je gladno. Oko drugog mjeseca života dijete će s okolinom početi
        komunicirati na način sličan “gukanju” - “guuu”, “aaah” ili “haaa”.
        Najčešće će proizvoditi baš te suglasnike i samoglasnike jer je njih
        vrlo lako dobiti u ležećem položaju. Malo kasnije, oko četvrtog mjeseca,
        dijete će se početi igrati sa svojim govornim organima te će najčešće
        pokušati oponašati zvukove koje čuje u okolini, kao što su glasovi “p”
        ili “d”. Oko šestog mjeseca dijete će proizvoditi nizove slogova iste
        strukture, kao što su “ba-ba-ba” ili “ma-ma”. Roditelji ponekad izgovor
        tih slogova tumače kao pravu riječ sa značenjem pa tako kažu da je
        njihovo dijete “progovorilo” već sa šest mjeseci. Međutim, kada dijete u
        toj dobi počinje izgovarati slogove “ma-ma”, ne radi se o djetetovoj
        prvoj riječi, nego o spontanoj proizvodnji slogova. Nakon toga, oko
        devetog mjeseca, dijete će početi koristiti nešto složenije, različite
        strukture slogova, poput “ba-pa-po”. Te strukture različitih nizova
        slogova s vremenom će, oko 12. mjeseca života, dobiti intonaciju vrlo
        sličnu materinskom jeziku. Osobe iz djetetove okoline, u ovoj dobi,
        najčešće će pomisliti da dijete govori nekim stranim jezikom koji ima
        intonaciju materinskog jezika. Prva godina djetetova života razdoblje je
        velikih i brzih promjena te nakon nje dijete ulazi u jezično razdoblje.
      </Text>
      <BlogImage
        imgSrc={preLinguisticPeriod}
        imgAlt="Predjezicno razdoblje izgovaranja glasova"
        imgTitle="Predjezicno razdoblje"
      />
      <Text as="h2">Jezično razdoblje</Text>
      <Text as="p">
        <b>
          {' '}
          Jezično razdoblje započinje s pojavom prve riječi koja ima značenje.{' '}
        </b>
        Tu prvu riječ nazivamo holofrazom jer uglavnom sadržava više značenja,
        npr. riječ “tata” može značiti “tata nosi me” ili “tata nahrani me”.
        Upotrebom prve riječi dijete će najčešće izražavati svoje želje ili
        potrebe. Nakon što dijete u svoj rječnik pohrani 50 riječi, dolazi do
        naglog bogaćenja, odnosno širenja rječnika koje se naziva leksički
        brzac. To se događa oko 18. mjeseca djetetova života. Upotrebom sve
        većeg broja riječi, djetetu se otvaraju nove mogućnosti u izražavanju i
        ovladavanju jezikom. Oko druge godine dijete počinje spajati riječi u
        dvočlane iskaze kao što su “mama pava” ili “neće to”. Nešto poslije, s
        oko dvije i pol godine, dijete počinje stvarati i višečlane iskaze,
        odnosno rečenice koje imaju tri ili više riječi. U razdoblju između
        treće i četvrte godine dijete bi trebalo imati usvojenu osnovu
        materinskog jezika. To znači da je usvojilo temelje svih jezičnih
        sastavnica, a jezik time postaje osnovno sredstvo u interakciji s
        osobama iz okoline. Naravno,
        <b>
          {' '}
          jezični razvoj tu ne prestaje, nego s godinama postaje sve složeniji i
          napredniji,{' '}
        </b>
        posebice kada dijete počne ovladavati pisanim jezikom.
      </Text>
      <BlogImage
        imgSrc={linguisticPeriod}
        imgAlt="Jezicno razdoblje izgovaranja glasova"
        imgTitle="Jezicno razdoblje"
      />
      <Text as="p">
        Dijete od rođenja do četvrte godine doživi velike promjene. Svaku od tih
        promjena možemo očekivati u određenim razdobljima. Da bi došlo do ovako
        brzog napretka u prvih nekoliko godina djetetovog života, dijete mora
        imati uredne kognitivne, slušne, govorne i ostale sposobnosti. Osim
        toga, djetetova okolina igra veliku ulogu u njegovu razvoju. Ona mora
        biti poticajna, podržavajuća i najvažnije, emocionalno topla.
      </Text>
      <Text as="p">Patricia Orlić, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Kuvač Kraljević, J., ur. (2015). Priručnik za prepoznavanje i
            obrazovanje djece s jezičnim teškoćama. Zagreb:
            Edukacijsko-rehabilitacijski fakultet Sveučilišta u Zagrebu
          </li>
        </ul>
      </BlogLiterature>
      <BlogInfographics infographicSrc={infographic} />
      <SectionBlog />
    </PageNew>
  );
};

export default LanguageDevelopment;
